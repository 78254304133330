<template>
  <div class="user">
    <div class="title">
      <PlateTitle title="企业信息" class="plate" />
    </div>
    <a-spin :spinning="spinUser">
      <div class="main">
        <div class="tips">
         <div>
          温馨提示：上传文件大小不能超过5MB！
         </div>

         <div>
          请如实填写企业信息，企业信息错误导致的保函失效将由投保企业自行承担!
         </div>
        </div>
        <a-form
          :form="form"
          :label-col="{ span: 4 }"
          :wrapper-col="{ span: 8 }"
        >
          <a-row>
            <a-col :span="12">
              <a-form-item label="企业名称" has-feedback>
                <a-input
                  v-decorator="[
                    'companyName',
                    { rules: [{ required: true, message: '请输入企业名称' }] },
                  ]"
                  placeholder="请输入企业名称"
                  style="width: 280px"
                />
                <!-- <span>{{ data.companyName }}</span> -->
              </a-form-item>
            </a-col>
            <a-col :span="12">
              <a-form-item label="统一社会信用代码" has-feedback>
                <a-input
                  v-decorator="[
                    'creditCode',
                    {
                      rules: [
                        { required: true, message: '请输入统一社会信用代码' },
                      ],
                    },
                  ]"
                  placeholder="请输入统一社会信用代码"
                  style="width: 280px"
                />
                <!-- <span>{{ data.creditCode }}</span> -->
              </a-form-item>
            </a-col>
          </a-row>
          <a-row>
            <a-col :span="12">
              <a-form-item label="基本户开户行" has-feedback>
                <a-input
                  v-decorator="[
                    'account',
                    {
                      rules: [
                        { required: true, message: '请输入基本户开户行' },
                      ],
                    },
                  ]"
                  placeholder="请输入基本户开户行"
                  style="width: 280px"
                />
              </a-form-item>
            </a-col>
            <a-col :span="12">
              <a-form-item label="基本户账号" has-feedback>
                <a-input
                  v-decorator="[
                    'bankNo',
                    {
                      rules: [{ required: true, message: '请输入基本户账号' }],
                    },
                  ]"
                  placeholder="请输入基本户账号"
                  style="width: 280px"
                />
              </a-form-item>
            </a-col>
          </a-row>
          <a-row>
            <a-col :span="12">
              <a-form-item label="企业类型" has-feedback>
                <a-select
                  v-decorator="[
                    'companyType',
                    { rules: [{ required: true, message: '请输入企业类型' }] },
                  ]"
                  placeholder="请输入企业类型"
                  style="width: 280px"
                >
                  <a-select-option
                    v-for="(item, index) in certTypeList"
                    :key="index"
                    :value="item.value"
                  >
                    {{ item.label }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :span="12">
              <a-form-item label="注册资本(万元)" has-feedback>
                <a-input
                  v-decorator="[
                    'registeredCapital',
                    { rules: [{ required: true, message: '请输入注册资本' }] },
                  ]"
                  placeholder="请输入注册资本"
                  style="width: 280px"
                />
              </a-form-item>
            </a-col>
          </a-row>
          <a-row>
            <a-col :span="12">
              <a-form-item label="企业地址" has-feedback>
                <a-input
                  v-decorator="[
                    'appCompanyAddress',
                    { rules: [{ required: true, message: '请输入企业地址' }] },
                  ]"
                  placeholder="请输入企业地址"
                  style="width: 280px"
                />
                <!-- <span>{{ data.appCompanyAddress }}</span> -->
              </a-form-item>
            </a-col>
            <a-col :span="12">
              <a-form-item label="法人姓名" has-feedback>
                <a-input
                  v-decorator="[
                    'legalName',
                    { rules: [{ required: true, message: '请输入法人姓名' }] },
                  ]"
                  placeholder="请输入法人姓名"
                  style="width: 280px"
                />
              </a-form-item>
            </a-col>
          </a-row>
          <a-row>
            <a-col :span="12">
              <a-form-item label="法人身份证号码" has-feedback>
                <a-input
                  v-decorator="[
                    'legalCertNo',
                    {
                      rules: [
                        { required: true, message: '请输入法人身份证号码' },
                      ],
                    },
                  ]"
                  placeholder="请输入法人身份证号码"
                  style="width: 280px"
                />
              </a-form-item>
            </a-col>
            <a-col :span="12">
              <a-form-item label="法人手机号" has-feedback>
                <a-input
                  v-decorator="[
                    'legalPhone',
                    {
                      rules: [{ required: true, message: '请输入法人手机号' }],
                    },
                  ]"
                  placeholder="请输入法人手机号"
                  style="width: 280px"
                />
              </a-form-item>
            </a-col>
          </a-row>
          <a-row>
            <a-col :span="12">
              <a-form-item label="营业执照" class="formItem">
                <a-form-item class="items">
                  <a-upload
                    v-decorator="[
                      'businessLicenseImage',
                      { rules: [{ validator: compareFile1 }] },
                    ]"
                    name="file"
                    :multiple="false"
                    :action="uploadUrl"
                    :headers="headers"
                    :file-list="businessLicenseImage"
                    accept=".jpg,.png"
                    @change="(info) => handleChange(info, '1')"
                  >
                    <a-button style="margin-right: 50px">
                      {{
                        businessLicenseImage.length > 0
                          ? "重新上传"
                          : "点击上传"
                      }}
                    </a-button>
                    <a-button
                      v-if="businessLicenseImage.length > 0"
                      @click="download('1')"
                    >
                      点击预览
                    </a-button>
                  </a-upload>
                </a-form-item>
              </a-form-item>
            </a-col>
            <a-col :span="12">
              <a-form-item label="法人身份证正面" class="formItem">
                <a-form-item class="items">
                  <a-upload
                    v-decorator="[
                      'legalPersonCardImage',
                      { rules: [{ validator: compareFile2 }] },
                    ]"
                    name="file"
                    :multiple="false"
                    :action="uploadUrl"
                    :headers="headers"
                    :file-list="legalPersonCardImage"
                    accept=".jpg,.png"
                    @change="(info) => handleChange(info, '2')"
                  >
                    <a-button style="margin-right: 50px">
                      {{
                        legalPersonCardImage.length > 0
                          ? "重新上传"
                          : "点击上传"
                      }}
                    </a-button>
                    <a-button
                      v-if="legalPersonCardImage.length > 0"
                      @click="download('2')"
                    >
                      点击预览
                    </a-button>
                  </a-upload>
                </a-form-item>
              </a-form-item>
            </a-col>
          </a-row>
          <a-row>
            <a-col :span="12">
              <a-form-item label="法人身份证反面" class="formItem">
                <a-form-item class="items">
                  <a-upload
                    v-decorator="[
                      'legalPersonCardImage2',
                      { rules: [{ validator: compareFile3 }] },
                    ]"
                    name="file"
                    :multiple="false"
                    :action="uploadUrl"
                    :headers="headers"
                    :file-list="legalPersonCardImage2"
                    accept=".jpg,.png"
                    @change="(info) => handleChange(info, '3')"
                  >
                    <a-button style="margin-right: 50px">
                      {{
                        legalPersonCardImage2.length > 0
                          ? "重新上传"
                          : "点击上传"
                      }}
                    </a-button>
                    <a-button
                      v-if="legalPersonCardImage2.length > 0"
                      @click="download('3')"
                    >
                      点击预览
                    </a-button>
                  </a-upload>
                </a-form-item>
              </a-form-item>
            </a-col>
            <a-col :span="12" />
          </a-row>
        </a-form>
        <div class="btn">
          <a-button
            type="primary"
            @click="submit"
            style="width: 120px; height: 40px"
          >
            {{ submitText }}
          </a-button>
        </div>
      </div>

      <!-- <div class="manage">
        <div>
          <img src="../../assets/image/icon-IDcard.png">
          <span class="userTitle">企业名称</span>
          <span class="userMain">{{ this.$store.state.info.companyName }}</span>
          <a class="flex1" />
        </div>
        <div>
          <img src="../../assets/image/icon-sj.png">
          <span class="userTitle">手机号码</span>
          <span
            class="userMain"
            v-if="userMsg.phoneNum"
          >{{ telephone(userMsg.phoneNum) }}</span>
          <span
            class="userMain"
            v-else
          >手机号码未绑定</span>
          <PhoneNumber
            ref="phone"
            class="flex1"
          />
        </div>
        <div>
          <img src="../../assets/image/icon-dz.png">
          <span class="userTitle">联系地址</span>
          <span class="userMain">{{ userMsg.location }}</span>
          <Address class="flex1" />
        </div>
        <div>
          <img src="../../assets/image/icon-mm.png">
          <span class="userTitle">登录密码</span>
          <span class="userMain" />
          <Password
            class="flex1"
            :psw-type="userMsg.password"
          />
        </div>
      </div> -->
    </a-spin>
    <a-modal
      v-model="visible"
      :cancel-button-props="{ style: { display: 'none' } }"
      @ok="handleOk"
      centered
      :mask-closable="false"
      :keyboard="false"
      ok-text="确定"
    >
      <p>用户未登录，请重新登录。</p>
    </a-modal>
  </div>
</template>

<script>
import { Modal } from "ant-design-vue";
import PlateTitle from "@/components/PlateTitle";
// import PhoneNumber from './components/PhoneNumber';
// import Address from './components/Address';
// import Password from './components/Password';
import { updateAcc } from "@/api/user/user.js";
import { telephone } from "@/utils/format.js";
import { certType } from "@/api/insure.js";
import { entInfo } from "@/api/entCertification/entCertification.js";
export default {
  name: "User",
  components: { PlateTitle },
  data() {
    return {
      visible: false,
      userMsg: {},
      spinUser: false,
      telephone,
      certTypeList: [],
      data: {},
      form: this.$form.createForm(this, { name: "loginForm" }),
      uploadUrl: process.env.VUE_APP_FIRST_URL + "/api/contract/upload",
      headers: {
        // authorization: 'authorization-text',
        Authorization: localStorage.getItem("token"),
      },
      businessLicenseImage: [], // 营业执照
      businessUrl: "", // 营业执照预览地址
      legalPersonCardImage: [], // 法人身份证头像面
      legalUrl: "", // 法人身份证头像面预览地址
      legalPersonCardImage2: [], // 法人身份证头像面
      legalUrl2: "", // 法人身份证头像面预览地址
      submitText: "提交",
    };
  },
  mounted() {
    this.info();
    this.refresh();
  },
  methods: {
    refresh() {
      this.spinUser = true;
      entInfo().then((res) => {
        if (res.data.status === "SUCCESS") {
          this.spinUser = false;
          this.data = res.data.data;
          console.log(this.data);
          if (this.data.legalPhone) {
            this.submitText = "修改";
          }
          this.form.setFieldsValue({
            companyName: this.data.companyName,
            creditCode: this.data.creditCode,
            appCompanyAddress: this.data.appCompanyAddress,
            registeredCapital: this.data.registeredCapital,
            legalName: this.data.legalName,
            legalCertNo: this.data.legalCertNo,
            companyType: this.data.companyType,
            legalPhone: this.data.legalPhone,
            bankNo: this.data.bankNo,
            account: this.data.account,
          });
          this.businessLicenseImage = this.data.businessLicenseImage
            ? [
                {
                  uid: "1",
                  name: "营业执照",
                  status: "done",
                  url: this.data.businessLicenseImage,
                },
              ]
            : [];
          if (this.data.legalPersonCardImage) {
            const legalPersonCardImage =
              this.data.legalPersonCardImage.split(",");
            this.data.legalPersonCardImage = legalPersonCardImage[0];
            this.data.legalPersonCardImage2 = legalPersonCardImage[1];
          }
          this.legalPersonCardImage = this.data.legalPersonCardImage
            ? [
                {
                  uid: "1",
                  name: "法人身份证正面",
                  status: "done",
                  url: this.data.legalPersonCardImage,
                },
              ]
            : [];
          this.legalPersonCardImage2 = this.data.legalPersonCardImage2
            ? [
                {
                  uid: "1",
                  name: "法人身份证反面",
                  status: "done",
                  url: this.data.legalPersonCardImage2,
                },
              ]
            : [];
        }
      });
    },
    submit() {
      let that = this;
      this.$confirm({
        title: "提示",
        cancelText: "取消",
        okText: "确认",
        content: (h) => <div style="color:red">请如实填写企业信息，企业信息错误导致的保函失效将由投保企业自行承担!</div>,
        onOk() {
          that.onClick();
        },
      });
    },
    onClick() {
      this.form.validateFields((err, values) => {
        if (!err) {
          let legalPersonCardImage = "";
          if (values.legalPersonCardImage) {
            legalPersonCardImage =
              values.legalPersonCardImage.file.response.data.uri +
              "," +
              values.legalPersonCardImage2.file.response.data.uri;
          } else {
            legalPersonCardImage =
              this.legalPersonCardImage[0].url +
              "," +
              this.legalPersonCardImage2[0].url;
          }
          const data = {
            ...values,
            businessLicenseImage: values.businessLicenseImage
              ? values.businessLicenseImage.file.response.data.uri
              : this.businessLicenseImage[0].url,
            legalPersonCardImage,
            id: this.data.customerId,
          };
          updateAcc(data).then((res) => {
            console.log(res);
            if (res.data.status === "SUCCESS" && this.submitText === "提交") {
              // this.$notification.success({ message: '提交成功' });
              // Modal.success({ title: "提交成功", okText: "确认" });
              this.$message.info("操作成功");
              this.refresh();
            } else if (
              res.data.status === "SUCCESS" &&
              this.submitText === "修改"
            ) {
              // this.$notification.success({
              //   message: '修改成功',
              //   closeIcon: () => {
              //     return null;
              //   },
              //   style: {
              //     transform: 'translate(-196px, 327px)',
              //     width: '200px',
              //     background: '#1890FF',
              //   },
              //   duration: 20000 });
              // Modal.success({ title: "修改成功", okText: "确认" });
              this.$message.info("操作成功");

              this.refresh();
            }
          });
        }
      });
    },
    handleOk() {
      this.$router.push({ name: "login" });
    },
    info() {
      // this.spinUser = true;
      // info().then(res => {
      //   if (res.data.errorMsg === '用户未登录') {
      //     this.visible = true;
      //   } else {
      //     this.spinUser = false;
      //     this.userMsg = res.data.data;
      //     // const _this = this.$refs.phone;
      //     // _this.phone = res.data.data.phoneNum;
      //   }
      // });

      certType().then((res) => {
        this.certTypeList = res.data.data;
      });
    },
    handleChange(info, num) {
      if (num === "1") {
        this.handleChange1(info);
      } else if (num === "2") {
        this.handleChange2(info);
      } else if (num === "3") {
        this.handleChange3(info);
      }
    },
    handleChange1(info) {
      this.businessLicenseImage =
        info.file.status && info.file.status !== "error"
          ? info.fileList.slice(-1)
          : [];
      this.businessUrl = info.file.response ? info.file.response.data.url : "";
      this.file1 = false;
      if (info.file.status === "done") {
        this.$message.success("上传成功");
      } else if (info.file.status === "error") {
        this.$message.error("上传失败");
        this.businessLicenseImage = [];
      } else if (info.file.status === "removed") {
        this.businessLicenseImage = [];
      }
    },
    handleChange2(info) {
      this.legalPersonCardImage = info.fileList.slice(-1);
      this.legalUrl = info.file.response ? info.file.response.data.url : "";
      this.file2 = false;
      if (info.file.status === "done") {
        this.$message.success("上传成功");
      } else if (info.file.status === "error") {
        this.$message.error("上传失败");
        this.legalPersonCardImage = [];
      } else if (info.file.status === "removed") {
        this.legalPersonCardImage = [];
      }
    },
    handleChange3(info) {
      this.legalPersonCardImage2 = info.fileList.slice(-1);
      this.legalUrl2 = info.file.response ? info.file.response.data.url : "";
      this.file2 = false;
      if (info.file.status === "done") {
        this.$message.success("上传成功");
      } else if (info.file.status === "error") {
        this.$message.error("上传失败");
        this.legalPersonCardImage2 = [];
      } else if (info.file.status === "removed") {
        this.legalPersonCardImage2 = [];
      }
    },
    download(num) {
      if (num === "1") {
        const url = this.businessUrl
          ? this.businessUrl
          : this.businessLicenseImage[0].url;
        const a = document.createElement("a");
        a.target = "_blank";
        a.href = this.businessUrl
          ? process.env.VUE_APP_FIRST_URL + url
          : process.env.VUE_APP_FIRST_URL +
            "/api/contract/download?source=" +
            url;
        a.click();
      } else if (num === "2") {
        const url = this.legalUrl
          ? this.legalUrl
          : this.legalPersonCardImage[0].url;
        const a = document.createElement("a");
        a.target = "_blank";
        a.href = this.legalUrl
          ? process.env.VUE_APP_FIRST_URL + url
          : process.env.VUE_APP_FIRST_URL +
            "/api/contract/download?source=" +
            url;
        a.click();
      } else if (num === "3") {
        const url = this.legalUrl2
          ? this.legalUrl2
          : this.legalPersonCardImage2[0].url;
        const a = document.createElement("a");
        a.target = "_blank";
        a.href = this.legalUrl2
          ? process.env.VUE_APP_FIRST_URL + url
          : process.env.VUE_APP_FIRST_URL +
            "/api/contract/download?source=" +
            url;
        a.click();
      }
    },
    compareFile1(rule, value, callback) {
      if (this.businessLicenseImage.length > 0) {
        callback();
      } else {
        callback("请上传文件");
      }
    },
    compareFile2(rule, value, callback) {
      if (this.legalPersonCardImage.length > 0) {
        callback();
      } else {
        callback("请上传文件");
      }
    },
    compareFile3(rule, value, callback) {
      if (this.legalPersonCardImage2.length > 0) {
        callback();
      } else {
        callback("请上传文件");
      }
    },
  },
};
</script>

<style lang="less" scoped>
.user {
  height: 660px;
  .main {
    /deep/ .ant-col-4 {
      width: 140px !important;
    }
    .btn {
      margin-top: 28px;
      display: flex;
      justify-content: center;
    }
  }
  .manage {
    div {
      height: 70px;
      display: flex;
      align-items: center;
      border-bottom: 1px solid #ebf2f7;
      img {
        width: 20px;
        height: 20px;
        margin-right: 10px;
      }
      .userTitle {
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #666666;
        width: 110px;
      }
      .userMain {
        width: 680px;
      }
      .flex1 {
        width: 70px;
        display: flex;
        flex-direction: row-reverse;
      }
    }
  }
  .items {
    margin-left: 0 !important;
    ::v-deep .ant-form-item-control {
      margin-left: 0 !important;
    }
    ::v-deep .ant-col {
      height: 30px;
    }
    ::v-deep .ant-upload-list-item-card-actions .anticon {
      display: none;
    }
    ::v-deep .ant-upload {
      display: flex;
    }
  }
}
.tips {
  padding: 0 0 20px 20px;
  color: red;
}
</style>
